import { Link } from "react-router-dom";
import './NotFound.css';
export function NotFound() {
    return <div id="notfound">
        <div className="notfound">
            <div className="notfound-404"><h1>404</h1></div><br />
            <h2>Oops! ESTA PAGINA NO EXISTE</h2>
            <p>LO SENTIMOS PERO LA PÁGINA QUE BUSCA NO EXISTE, HA SIDO ELIMINADA. EL NOMBRE CAMBIÓ O NO ESTÁ DISPONIBLE TEMPORALMENTE</p>
            <Link to="/">VOLVER AL INICIO </Link>
        </div>
    </div>
}