import { Row, Col, Container } from 'react-bootstrap';
import { UseIcon } from '../Components/Icons';
import { Html } from '../Components/Html';
import './Preguntas.css';
export default function Preguntas({ data: { props, itens, id } }: { data: any }) {
    const { color, cols } = props;
    const Rows: JSX.Element[] = [];
    const Column = ({ itens }: { itens: any[] }) => {
        return <Col className='col-pregunta' style={{ minHeight: '100px' }}
            md={cols === 3 ? 4 : cols === 2 ? 6 : 12}>
            {itens.map((iten) => <Itens key={'iten' + iten.id} config={props} iten={iten} />)}
        </Col>
    }
    for (let i = 0, init = 0; i < cols; i++) {
        Rows.push(<Column key={'column' + id + i} itens={itens.filter((_a: any, c: number) =>
            c >= init && (c < init + (itens.length / cols)))} />)
        init += (itens.length / cols);
    }
    return <Row className='m-0' style={{ background: color }}>
        <Container style={{ marginBlock: '20px' }}><Row>{Rows}</Row></Container>
    </Row>
}
export function Itens({
    config: { back: iback, border, orientation }, iten }: any) {
    if (iten === undefined) return <></>;
    const { html, props } = iten;
    const { title, icon, color, back, aux, fill } = props;
    const p: any = { name: "pregunta" }
    return <details className="card m-0" style={{ background: back }} {...p}>
        <summary className={`d-flex flex-${orientation}`}>
            <div style={{
                width: '3rem', height: '3rem', textAlign: 'center', paddingTop: border ? '.2rem' : '.5rem',
                borderRadius: iback === "circle" ? "3rem" : iback === "rounded" ? "1rem" : undefined,
                border: (border && iback !== "none") ? `.35rem solid ${fill}` : undefined,
                background: iback !== "none" ? aux : undefined
            }}>
                <UseIcon icon={icon} width="2rem" height="2rem" fill={fill} />
            </div>
            <Html style={{ minWidth: 'calc(100% - 3rem)' }} className='p-1' html={title} />
        </summary>
        <div className="card-body pb-5" >
            <Html className='w-100' html={html} style={{ color }} />
        </div>
    </details >
}