import { css } from '@emotion/css';
import { Container, FormGroup, Button } from 'react-bootstrap';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { Invert } from '../Components/Invert';
import Input, { Correo, Telefono, valCorreo } from '../Utils/Input';
import { post } from '../Utils/Post';
export default function Contact({ data }: { data: any }) {
    const { props: { padding, input, inputText, button, buttonText, backgroundColor } } = data;
    const [nombre, setNombre] = useState('');
    const [correo, setCorreo] = useState('');
    const [asunto, setAsunto] = useState('');
    const [telefono, setTelefono] = useState('');
    const [mensaje, setMSJ] = useState('');
    const [valid, setValit] = useState(false);
    const suscribirse = () => {
        if (val(nombre, correo, asunto, telefono, mensaje))
            post('/api/Contacto/me/nuevo',
                { nombre, correo, asunto, telefono, mensaje },
                () => toast('GRACIAS POR CONTACTARME', { type: toast.TYPE.SUCCESS }))
        else setValit(true)
    }
    return <div style={{ paddingBlock: padding, background: backgroundColor }}>
        <Container>
            <Invert data={data}>
                <FormGroup className={getCSS(input, inputText, button, buttonText)}>
                    <Input value={nombre} setData={setNombre} placeholder="Ingresa tu nombre" valid={valid}
                        title={!valid ? "Ingresa tu nombre" : "Debe colocar el nombre"}
                        next={() => { document.getElementById('correo')?.focus() }} style={{ gridArea: 'a' }} />
                    <Correo value={correo} setData={setCorreo} placeholder="Ingresa tu correo" id='correo' valid={valid}
                        title={!valid ? "Ingresa tu nombre" : "Debe colocar el correo"}
                        next={() => { document.getElementById('asunto')?.focus() }} style={{ gridArea: 'b' }} />
                    <Input value={asunto} setData={setAsunto} placeholder="Ingresa el asunto" id='asunto' valid={valid}
                        title={!valid ? "Ingresa tu nombre" : "Debe colocar el asunto"}
                        next={() => { document.getElementById('telefono')?.focus() }} style={{ gridArea: 'c' }} />
                    <Telefono value={telefono} setData={setTelefono} placeholder="Ingresa su telefono" id='telefono' valid={valid}
                        title={!valid ? "Ingresa tu nombre" : "Debe colocar el telefono"}
                        next={() => { document.getElementById('msj')?.focus() }} style={{ gridArea: 'd' }} />
                    <Input value={mensaje} setData={setMSJ} as='textarea' id='msj' next={suscribirse} valid={valid}
                        title={!valid ? "Por favor, escriba su mensaje aqui" : "Debe colocar el mensaje"}
                        placeholder="Por favor, escriba su mensaje aqui" style={{ gridArea: 'e' }} />
                    <Button onClick={suscribirse} className='btn-block' size='lg' style={{ gridArea: 'f' }}>Subscribirse</Button>
                </FormGroup>
            </Invert>
        </Container>
    </div>
}
function getCSS(color1: string, color2: string, color3: string, color4: string) {
    return css`
    padding: 0 0 0 0 !important;
    margin: .25rem .25rem .25rem .25rem !important;
    display: grid;
    gap: 0;
    grid-auto-flow: dense;
    grid-template-areas:    "a a b b"
                            "c c d d"
                            "e e e e"
                            "f f f f";
    &> input, &> textarea{
        width: 100% !important;
        border-radius: 0 !important;
        &:first-child{border-radius: .3rem 0 0 0 !important;}
        &:first-child + input{border-radius: 0 .3rem 0 0 !important;}
        ${toCSS(color1, color2)}
    }
    &> button {
        ${toCSS(color3, color4)}
        border: 0 !important;
        border-radius: 0 0 .3rem .3rem !important;
    }
    `;
}
function toCSS(back: string, text: string) {
    return `
    background: ${back} !important;
    color: ${text} !important;
    border: 2px solid ${text} !important;
    font-weight: 700 !important;
    &::placeholder{
        opacity: .5 !important;
        color: ${text} !important;
        font-weight: 500 !important;
    }`
}
function val(nombre: string, correo: string, asunto: string, telefono: string, msj: string) {
    // eslint-disable-next-line
    const t = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g.test(telefono);
    return (nombre !== '') && (
        (correo !== '' && valCorreo(correo)) ||
        ((telefono !== '') && t)
    ) && (msj !== '') && (asunto !== '');
}