import { panels, page, menu } from './Data';
import Menu from './Template/Menu';
import Chat from './Template/Chat';
import { useParams } from "react-router-dom";
import { busqueda } from './Utils/Formatos';
import { useEffect, useCallback, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import './Noti.css';
import { NotFound } from './Utils/NotFound';
import Footer from './Template/Footer';
import { Preview } from './Panels';
export default function App() {
  const [mont, setMont] = useState(false);
  const { pageSLE, panelSLE } = useParams<any>();
  const { id } = busqueda(page, pageSLE);//
  useEffect(() => { setMont(true); setTimeout(() => window.scrollTo(0, 0), 1); }, []);
  const chance = useCallback(() => {
    if (mont && panelSLE !== undefined) {
      moveId(panelSLE);
    }
    else setTimeout(() => chance(), 10)
  }, [panelSLE, mont]);
  useEffect(() => { if (mont) setTimeout(() => window.scrollTo(0, 0), 1) }, [pageSLE, mont]);
  useEffect(chance, [pageSLE, panelSLE, chance]);
  return (<>
    <Menu />
    {id === -1 ? <NotFound /> : panels
      .filter(({ pagina, estado }) => id === pagina && estado === 1)
      .sort((a, b) => a.indice - b.indice)
      .map(p => <div key={p.type + p.id} className='w-100 m-0 p-0' id={`panel${p.id}`}>
        <Preview panel={p} />
      </div>)}
    <Chat />
    <Footer />
    <ToastContainer position='bottom-right' />
  </>);
}
function moveId(id: string) {
  setTimeout(() => {
    const div = document.getElementById(id);
    if (div !== null) {
      const { top } = div.getBoundingClientRect();
      const par = (top - menu.height) / 40;
      scrollTo(par);
    }
  }, 10)
}
function scrollTo(par: number, i = 0) {
  if (i < 40) setTimeout(() => {
    window.scrollTo(0, window.scrollY + par)
    scrollTo(par, i + 1)
  }, i);
}
let ainimates: (() => void)[] = [];
export function addAnimate(func: () => void) {
  ainimates.push(func);
}
export function removeAnimate(func: () => void) {
  ainimates = ainimates.filter(i => i !== func);
}
// function ainimated() {
//   ainimates.forEach(func => func());
// }