import { useState } from "react";
import { FormControl, FormControlProps } from "react-bootstrap";
type padre = FormControlProps & React.HTMLProps<HTMLInputElement>;
export interface Props extends padre {
    setData: (data: any) => void,
    value: string
    next?: () => void
    valid?: boolean
}
export default function Input(p: Props) {
    const [select, setSelect] = useState(false);
    const v = p.value;
    const value = () => {
        if (select) return { defaultValue: p.value };
        else return { value: p.value }
    }
    const Props = () => {
        let props: any = { ...p };
        props.value = undefined;
        props.style = { ...p.style, ...getS(v, p.valid) }
        return { ...props, ...value() };
    }
    return <FormControl {...Props()} size='lg'
        onFocus={() => { setSelect(true) }}
        onBlur={() => { setSelect(false) }}
        onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.keyCode === 13) if (p.next) p.next();
            if (p.onKeyUp) p.onKeyUp(e);
        }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            let { value } = e.currentTarget;
            e.currentTarget.value = value;
            p.setData(value);
        }}
    />
}
export function Correo(p: Props) {
    const [select, setSelect] = useState(false);
    const v = p.value;
    const value = () => {
        if (select) return { defaultValue: p.value };
        else return { value: p.value }
    }
    const Props = () => {
        let props: any = { ...p };
        props.value = undefined;
        props.style = { ...p.style, ...getS(v, p.valid && !valCorreo(v)) }
        return { ...props, ...value() };
    }

    return <FormControl {...Props()} size='lg'
        onFocus={() => { setSelect(true) }}
        onBlur={() => { setSelect(false) }}
        onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.keyCode === 13) if (p.next) p.next();
            if (p.onKeyUp) p.onKeyUp(e);
        }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            let { value } = e.currentTarget;
            e.currentTarget.value = value;
            p.setData(value);
        }}
    />
}
export function Telefono(p: Props) {
    const [select, setSelect] = useState(false);
    const v = p.value;
    const value = () => {
        if (select) return { defaultValue: p.value };
        else return { value: p.value }
    }
    const Props = () => {
        let props: any = { ...p };
        props.value = undefined;
        props.style = { ...p.style, ...getS(v, p.valid) }
        return { ...props, ...value() };
    }
    return <FormControl {...Props()} size='lg'
        onFocus={() => { setSelect(true) }}
        onBlur={() => { setSelect(false) }}
        onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.keyCode === 13) if (p.next) p.next();
            if (p.onKeyUp) p.onKeyUp(e);
        }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            let { value } = e.currentTarget;
            // eslint-disable-next-line
            if (!(/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g.test(value))) {
                value = value.slice(0, -1);
            }
            e.currentTarget.value = value;
            p.setData(value);
        }}
    />
}
function getS(val: string, valid?: boolean) {
    if (!valid) return {};
    if (val === '') return { borderColor: 'red' }
}
export const valCorreo = (campo: string) => {
    // eslint-disable-next-line
    const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (emailRegex.test(campo)) return true
    else return false

}