import { lazy, Suspense } from 'react';
import { Row, Container, Card, Image } from 'react-bootstrap';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { css } from '@emotion/css';
import { getImagenURL } from '../Utils/Imagen';
import Cargando from '../Components/Cargando';
import './BannerImagen.css';
const SliderLazy = lazy(() => import('../Utils/SliderLazy'));

export default function BannerImagen({ data: { itens, props } }: any) {
    const { color, inter, up, down } = props;
    const { length: l } = itens;
    const settings = {
        autoplaySpeed: 5000,
        slidesToShow: 3 > l ? l : 3,
        slidesToScroll: 1,
        responsive: [
            { breakpoint: 570, settings: { slidesToShow: 2 > l ? l : 2 } }
        ]
    }
    return <Row className='p-0 m-0'>
        <div className="w-100 p-0 imagen-banner-bottom" style={{ background: up }} />
        <Container className='p-0 w-100'>
            <Card className={`${getCSS(inter)} imagen-banner m-0 w-100`} style={{ background: color, padding: '.4rem 3rem .4rem 3rem' }}>
                <Suspense fallback={<Cargando />}>
                    <SliderLazy settings={settings}>
                        {itens.map(({ props: i, id }: any) => <div key={`BannerImagenDiv${id}`}>
                            <Image key={`BannerImagen${id}`} className={`m-auto`} src={getImagenURL(i.img)} alt={`banner ${id}`} />
                        </div>)}
                    </SliderLazy>
                </Suspense>
            </Card>
        </Container>
        <div className="w-100 p-0 imagen-banner-top" style={{ background: down }} />
    </Row>
}
function getCSS(color: string) {
    return css`button: before{color: ${color} !important;}`;
}