import { lazy, Suspense } from 'react';
import { Container } from 'react-bootstrap';
import { Invert } from '../Components/Invert';
import Cargando from '../Components/Cargando';
const ReactPlayer = lazy(() => import('react-player'));
export default function Video({ data }: { data: any }) {
    const { props: { padding, video, backgroundColor: c, } } = data;
    return <div style={{ paddingBlock: padding, background: c, }}>
        <Container>
            <Invert data={data}>
                <Suspense fallback={<Cargando />}>
                    <ReactPlayer width='100%' controls url={video} />
                </Suspense>
            </Invert>
        </Container>
    </div>
}

