import { lazy, Suspense } from 'react';
import { Row, Container, Card, Col } from 'react-bootstrap';
import { Zoom } from '../../Components/Zoom';
import { Html } from '../../Components/Html';
import { useState } from 'react';
import { getImagenURL } from '../../Utils/Imagen';
import { getCSSCarrusel } from './styles';
import Cargando from '../../Components/Cargando';
const SliderLazy = lazy(() => import('../../Utils/SliderLazy'));
export default function Cargos({ data: { itens, props } }: any) {
    const [open, setOpen] = useState(false);
    const [select, setSelect] = useState(itens[0].id);
    const settings = {
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            { breakpoint: 1024, settings: { slidesToShow: 3 } },
            { breakpoint: 780, settings: { slidesToShow: 2 } },
            { breakpoint: 570, settings: { slidesToShow: 1 } }
        ]
    }
    const { color } = props
    const i = itens.map((i: any) => <Itens key={'itens' + i.id} iten={i}
        props={props} click={() => { setOpen(true); setSelect(i.id) }} />);
    return <Row className='m-0' style={{ background: color, padding: '0 0 2rem 0' }}>
        <Zoom color={color} imgs={itens.map((i: any) => { return { id: i.id, src: i.props.img } })}
            select={select} setSelect={setSelect} open={open} setOpen={setOpen} />
        <Container className={getCSSCarrusel(color)}>
            <Suspense fallback={<Cargando />}>
                <SliderLazy settings={settings}>{[...i, ...i]}</SliderLazy>
            </Suspense>
        </Container>
    </Row>
}
function Itens({ props: { gap }, iten: { id, html, props: { img, color, back } }, click }: any) {
    const style = { background: back, color };
    return <Col className='m-0 p-0'>
        <Card style={{ margin: `0 ${gap}px 0 ${gap}px` }} className="c-content-person-1 c-option-2 mt-2">
            <Card.Img style={{ aspectRatio: '1' }} onClick={click} variant="top" src={getImagenURL(img, 2)} alt={`cargos ${id}`} />
            <Html className='card-body' style={style} html={html} />
        </Card>
    </Col >
}