import { css } from '@emotion/css';
export function getWidth(xs: any, sm: any, md: any, lg: any, xl: any, xxl: any,) {
    return css`
        &> .col{
            padding: 1.5rem  1.5rem  1.5rem  1.5rem;
            margin-left: ${xs.offset}%;
            -webkit-flex: 0 0 ${xs.span - xs.offset}%;
            -ms-flex: 0 0 ${xs.span - xs.offset}%;
            flex: 0 0 ${xs.span - xs.offset}%;
            max-width: ${xs.span - xs.offset}%;
        }
        @media (min-width: 576px) {
            &> .col{
                margin-left: ${sm.offset}%;
                -webkit-flex: 0 0 ${sm.span - sm.offset}%;
                -ms-flex: 0 0 ${sm.span - sm.offset}%;
                flex: 0 0 ${sm.span - sm.offset}%;
                max-width: ${sm.span - sm.offset}%;
            }
        }
        @media (min-width: 768px) {
            &> .col{
                margin-left: ${md.offset}%;
                -webkit-flex: 0 0 ${md.span - md.offset}%;
                -ms-flex: 0 0 ${md.span - md.offset}%;
                flex: 0 0 ${md.span - md.offset}%;
                max-width: ${md.span - md.offset}%;
            }
        }
        @media (min-width: 992px) {
            &> .col{
                margin-left: ${lg.offset}%;
                -webkit-flex: 0 0 ${lg.span - lg.offset}%;
                -ms-flex: 0 0 ${lg.span - lg.offset}%;
                flex: 0 0 ${lg.span - lg.offset}%;
                max-width: ${lg.span - lg.offset}%;
            }
        }
        @media (min-width: 1200px) {
            &> .col{
                margin-left: ${xl.offset}%;
                -webkit-flex: 0 0 ${xl.span - xl.offset}%;
                -ms-flex: 0 0 ${xl.span - xl.offset}%;
                flex: 0 0 ${xl.span - xl.offset}%;
                max-width: ${xl.span - xl.offset}%;

            }
        }
        @media (min-width: 1400px) {
            &> .col{
                margin-left: ${xxl.offset}%;
                -webkit-flex: 0 0 ${xxl.span - xxl.offset}%;
                -ms-flex: 0 0 ${xxl.span - xxl.offset}%;
                flex: 0 0 ${xxl.span - xxl.offset}%;
                max-width: ${xxl.span - xxl.offset}%;
            }
        }`;
}
export function getOrientacion(orie: boolean, xs: number, sm: number, md: number, lg: number, xl: number, xxl: number) {
    return orie ? getLeft(xs, sm, md, lg, xl, xxl) : getRight(xs, sm, md, lg, xl, xxl)
}
function getLeft(xs: number, sm: number, md: number, lg: number, xl: number, xxl: number) {
    return css`
        width:${xs}%;
        @media (min-width: 576px) {
            width:${sm}%;
        }
        @media (min-width: 768px) {
            width:${md}%;
        }
        @media (min-width: 992px) {
            width:${lg}%;
        }
        @media (min-width: 1200px) {
            width:${xl}%;
        }
        @media (min-width: 1400px) {
            width:${xxl}%;
        }`;
}
function getRight(xs: number, sm: number, md: number, lg: number, xl: number, xxl: number) {
    return css`
        width:${getW(xs)}%;
        @media (min-width: 576px) {
            width:${getW(sm)}%;
        }
        @media (min-width: 768px) {
            width:${getW(md)}%;
        }
        @media (min-width: 992px) {
            width:${getW(lg)}%;
        }
        @media (min-width: 1200px) {
            width:${getW(xl)}%;
        }
        @media (min-width: 1400px) {
            width:${getW(xxl)}%;
        }`;
}
const getW = (w: number) => (w === 100) ? 100 : 100 - w