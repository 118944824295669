import { css } from '@emotion/css';
import { Row, Col } from 'react-bootstrap';
import { Html } from '../Components/Html';
import { getWidth } from '../Utils';
import { getImagenURL } from '../Utils/Imagen';
export default function Paralax2({ data: { props: { xs, sm, md, lg, xl, xxl, color, image }, html } }: { data: any }) {
    return <div className={css`overflow: hidden;background-image: url(${getImagenURL(image)});background-attachment: fixed;background-position: center center;background-size: cover;background-color: transparent;`}>
        <Row className={`${getWidth(xs, sm, md, lg, xl, xxl)} m-0 p-0`}>
            <Col style={{ background: color, height: '100%' }}>
                <Html style={{ height: '100%', width: '100%' }} html={html} />
            </Col>
        </Row>
    </div>
}