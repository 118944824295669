import { don } from "@victorequena22/conneccion";

export async function localImage(src: string, set: (s: string) => void) {
    const n64 = localStorage.getItem(src);
    if (n64 === null) {
        const img = await createImage(src);
        const canvas = document.createElement('canvas') as HTMLCanvasElement;
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
        if (!ctx) set(src);
        else {
            ctx.drawImage(img, 0, 0);
            const b64 = canvas.toDataURL('image/jpeg', 1);
            localStorage.setItem(src, b64);
            set(b64);
        }
    } else set(n64);
}
export function getLocalImage(src: string) {
    const n64 = localStorage.getItem(src);
    if (n64 === null) return src;
    return n64;
}
export function valLocalImage(src: string) {
    const n64 = localStorage.getItem(src);
    if (n64 === null) return false;
    return true;
}
export const createImage = (url: string) => new Promise<HTMLImageElement>((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image as HTMLImageElement))
    image.addEventListener('error', (_error) => reject(null))
    image.setAttribute('crossOrigin', 'anonymous')
    image.src = url
})

export async function getV(src: string, set: (s: string) => void) {
    const { width, height } = await createImage(src);
    set((height / width) > .5 ? 'h' : 'w');
}

export function getImagenURL(src?: string, _w = 1) {
    // let width = Math.round((window.innerWidth / w) * 1.3);
    // if (src && src.indexOf('/api/Media/ver/') > -1) {
    //     return `${don}/${src}`;
    // }
    return `${don}/${src}`;
}