import { Html } from "../../Components/Html";
import { Icon2 } from "../../Components/Icons";

export function Itens({ ori, config: { card, border1, border2, back: iback, orientation, font }, iten, i }: any) {
    if (iten === undefined) return <></>;
    const { html, props: { icon, color, back, aux, fill } } = iten;
    const style = {
        border: (border1) ? `5px solid ${fill}` : undefined,
        background: card !== 'none' ? back : undefined,
        borderRadius: card === 'rounded' ? `${font / 3}rem` : undefined,
        padding: '10px 10px 10px 10px'
    }
    const d = xor((orientation === ''), !!(i % 2)) ? 'row' : 'row-reverse';
    return <div style={style} className={`d-flex flex-${d}  vivify animationObject fadeIn${ori} `}>
        <div className="d-flex justify-content-center">
            <Icon2 icon={icon} font={font} fill={fill} back={iback} border={border2} aux={aux} />
        </div>
        <div className="caption d-flex align-items-center h-100 p-1">
            <Html html={html} style={{ color }} />
        </div>
    </div>
}

function xor(a: boolean, b: boolean): boolean {
    return (a && !b) || (!a && b);
}