import { useState } from 'react';
import { css } from '@emotion/css';
import { chats, empresa, screen } from '../Data';
import { UseIcon } from '../Components/Icons';
import { Html } from '../Components/Html';
export default function Chat() {
    const [open, setOpen] = useState(false);
    const { nombre } = empresa;
    const { tiempo, icon, colorBotton, colorChatButton, linkText, link, html, activo } = chats

    return activo ? <div className={getCSS(chats)}>
        <div className="whatsapp" >
            <div className={(open) ? "whatsapp-visible" : "whatsapp-hide"}
                style={{ width: (screen.width < 360) ? screen.width : "360px", borderRadius: '10px', overflow: 'hidden' }}>
                <div color="rgb(255, 255, 255)" onClick={() => setOpen(false)} role="button" className="whatsapp-cerrar" />
                <div className="whatsapp-header">
                    <div className="whatsapp-img-conten">
                        <div className="whatsapp-img-circle">
                            <div className="whatsapp-img"></div>
                        </div>
                    </div>
                    <div className="whatsapp-header-title">
                        <div className="whatsapp-header-nombre">{nombre}</div>
                        <div className="whatsapp-header-msn">{tiempo}</div>
                    </div>
                </div>
                <div className="whatsapp-chat-conten">
                    <div className="whatsapp-chat-dialog">
                        <div className="whatsapp-chat-aux" style={{ opacity: 0 }}>
                            <div className="whatsapp-chat-space"></div>
                        </div>
                        <div className="whatsapp-chat-pop" style={{ opacity: 1 }}>
                            <div className="whatsapp-chat-pop-header">
                                {nombre}
                            </div>
                            <Html className='whatsapp-chat-pop-msn' html={html} />
                        </div>
                    </div>
                    <a target="_blank" role="button" className="whatsapp-chat-button"
                        href={link} title="WhatsApp" rel="noreferrer">
                        <UseIcon icon={icon} width={20} height={20} fill={colorChatButton} />
                        <span className="whatsapp-chat-button-msn">{linkText}</span>
                    </a>
                </div>
            </div>
            <div title={nombre} onClick={() => setOpen(!open)} style={{ marginTop: 35, border: 'none' }} className="whatsapp-button">
                <div className="Icon__Component-sc-6s5exc-0 eaUCvE Bubble__StyledIcon-sc-83hmjh-1 whatsapp-button-svg">
                    <UseIcon icon={icon} width={32} height={32} fill={colorBotton} />
                </div>
            </div>
        </div>
    </div> : <></>;
}
export function getCSS(p: any) {
    return css`
    .whatsapp {
        display: inline-block;
        position: fixed;
        bottom: 0px;
        z-index: 9999999;
        left: 0px;
        right: auto;
    }
    .whatsapp-visible {
        ${visible()}
    }
    .whatsapp-hide {
        ${hide()}
    }
    ${cerrar()}
    .whatsapp-header {
        ${header(p)}
    }
    ${content(p)}
    .whatsapp-header-title {
        margin-left: 16px;
        margin-right: 16px;
    }
    .whatsapp-header-nombre {
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        color: ${p.colorHeader};
    }
    .whatsapp-header-msn {
        font-size: 13px;
        line-height: 18px;
        margin-top: 4px;
        color: ${p.colorHeader};
    }
    ${img(p)}
    ${chat(p)}
    .whatsapp-button {
        height: 64px;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 12px;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        cursor: pointer;
        user-select: none;
        position: relative;
        background-color: ${p.backBotton};
        margin-bottom: 20px;
        margin-right: 20px;
        margin-left: 20px;
        width: 64px;
        border-radius: 50%;
        outline: transparent;
    }
    .whatsapp-button::before {
        content: "";
        width: 10px;
        height: 10px;
        background-color: rgb(255, 0, 0);
        display: block;
        position: absolute;
        z-index: 1;
        right: 4px;
        top: 4px;
        border-radius: 50%;
    }
    .whatsapp-button-svg {
        display: flex;
    }`
}
function visible() {
    return `
    box-shadow: rgba(0, 0, 0, 0.1) 0px 12px 24px 0px;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: white;
    transform: translate3d(0px, 0px, 0px);
    opacity: 1;
    pointer-events: all;
    visibility: visible;
    touch-action: auto;
    margin-bottom: 96px;
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: auto;
    margin-left: 20px;
    border-radius: 10px;
    overflow: hidden;
    transition: opacity 0.3s ease 0s, margin 0.3s ease 0s,
                visibility 0.3s ease 0s;
    `
}
function hide() {
    return `
    box-shadow: rgba(0, 0, 0, 0.1) 0px 12px 24px 0px;
    display: flex;
    flex-direction: column;
    width: 100%;
    opacity: 0;
    pointer-events: none;
    touch-action: none;
    visibility: hidden;
    background-color: white;
    transform: translate3d(0px, 0px, 0px);
    margin-bottom: 80px;
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: auto;
    margin-left: 20px;
    border-radius: 10px;
    overflow: hidden;
    transition: opacity 0.3s ease 0s, margin 0.3s ease 0s,
        visibility 0.3s ease 0s;
    `
}
function cerrar() {
    return `.whatsapp-cerrar {
        position: absolute;
        top: 8px;
        right: 8px;
        width: 20px;
        height: 20px;
        opacity: 0.4;
        cursor: pointer;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        transition: all 0.3s ease 0s;
        outline: transparent;
    }
    .whatsapp-cerrar::before {
        transform: rotate(45deg);
    }
    .whatsapp-cerrar::before,
    .whatsapp-cerrar::after {
        content: "";
        position: absolute;
        width: 12px;
        height: 2px;
        background-color: white;
        display: block;
        border-radius: 2px;
    }
    .whatsapp-cerrar::after {
        transform: rotate(-45deg);
    }
    .whatsapp-cerrar::before,
    .whatsapp-cerrar::after {
        content: "";
        position: absolute;
        width: 12px;
        height: 2px;
        background-color: white;
        display: block;
        border-radius: 2px;
    }`
}
function header({ backHeader, colorHeader, backChat }: any) {
    return `
        color: ${colorHeader};
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        background: ${backHeader};
        padding: 24px 20px;
        ${(backChat === backHeader) ? `border-bottom: ${colorHeader}33 solid 2px` : ''}
`
}
function content({ backHeader, backChat }: any) {
    return `
    .whatsapp-img-conten {
        width: 52px;
        height: 52px;
        display: block;
        position: relative;
        flex-shrink: 0;
    }
    .whatsapp-img-conten::before {
        content: "";
        bottom: 0px;
        right: 0px;
        width: 12px;
        height: 12px;
        box-sizing: border-box;
        background-color: ${backChat};
        display: block;
        position: absolute;
        z-index: 1;
        border-radius: 50%;
        border-width: 2px;
        border-style: solid;
        border-color: ${backHeader};
        border-image: initial;
    }`
}
function img({ img }: any) {
    return `
    .whatsapp-img-circle {
        height: 100%;
        width: 100%;
        position: relative;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        overflow: hidden;
        border-radius: 50%;
    }
    .whatsapp-img-circle::after {
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        position: absolute;
        content: "";
        border-radius: 50%;
        border-width: 1px;
        border-style: solid;
        border-color: rgba(0, 0, 0, 0.1);
        border-image: initial;
        overflow: hidden;
    }
    .whatsapp-img {
        min-width: 100%;
        height: 100%;
        flex-shrink: 0;
        background-size: cover;
        background-image: url("${img}");
        background-position: center center;
        background-repeat: no-repeat;
    }`
}
function chat({ backChat, estilo, backComment, colorComment, backChatButton, colorChatButton }: any) {
    return `
    .whatsapp-chat-conten {
        background-color: ${backChat};
        position: relative;
        max-height: 382px;
        padding: 20px 20px 20px 10px;
        overflow: auto;
    }
    .whatsapp-chat-conten::before {
        display: block;
        position: absolute;
        content: "";
        left: 0px;
        top: 0px;
        height: 100%;
        width: 100%;
        z-index: 0;
        opacity: 0.08;
        ${estilo === 'whatsapp' || estilo === 'telegram' ? 'background-image: url(https://elfsight.com/assets/chats/patterns/whatsapp.png);' : ''}
    }
    .whatsapp-chat-dialog {
        display: flex;
        z-index: 1;
    }
    .whatsapp-chat-aux {
        background-color: ${backComment};
        width: 52.5px;
        height: 32px;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        margin-left: 10px;
        opacity: 0;
        z-index: 1;
        box-shadow: rgba(0, 0, 0, 0.13) 0px 1px 0.5px;
        border-radius: 16px;
        transition: all 0.1s ease 0s;
    }
    .whatsapp-chat-space {
        position: relative;
        display: flex;
    }
    .whatsapp-chat-pop {
        background-color: ${backComment};
        position: relative;
        opacity: 0;
        transform-origin: center top;
        z-index: 2;
        box-shadow: rgba(0, 0, 0, 0.13) 0px 1px 0.5px;
        margin-top: 4px;
        margin-left: -54px;
        max-width: calc(100% - 66px);
        padding: 7px 14px 6px;
        border-radius: 0px 8px 8px;
        transition: all 0.3s ease 0s;
    }
    .whatsapp-chat-pop::before {
        position: absolute;
        background-size: contain;
        content: "";
        top: 0px;
        left: -12px;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        border-right: 12px solid ${backComment};
        border-bottom: 10px solid transparent;
    }
    .whatsapp-chat-pop-header {
        font-size: 13px;
        font-weight: 700;
        line-height: 18px;
        color: ${colorComment}AA;
    }
    .whatsapp-chat-pop-msn {
        font-size: 14px;
        line-height: 19px;
        margin-top: 4px;
        color: ${colorComment};
    }
    .whatsapp-chat-pop-time {
        text-align: right;
        margin-top: 4px;
        font-size: 12px;
        line-height: 16px;
        color: ${colorComment};
        margin-right: -8px;
        margin-bottom: -4px;
    }
    .whatsapp-chat-button {
        color: ${colorChatButton};
        font-size: 15px;
        font-weight: 700;
        line-height: 20px;
        cursor: pointer;
        position: relative;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        -webkit-appearance: none;
        padding: 8px 12px;
        border-radius: 20px;
        border-width: initial;
        border-style: none;
        border-color: initial;
        border-image: initial;
        background: ${backChatButton};
        margin: 20px;
        overflow: hidden;
    }
    .whatsapp-chat-button::before {
        content: "";
        position: absolute;
        display: block;
        opacity: 0;
        transform: scale(1);
        background-color: ${backChatButton};
        z-index: 0;
        width: 10px;
        height: 10px;
        bottom: -5px;
        transition: all 0.2s ease 0s;
        border-radius: 20px;
    }
    .whatsapp-chat-button-svg {
        width: 16px;
        height: 16px;
        fill: ${colorChatButton};
        z-index: 1;
        flex: 0 0 16px;
    }
    .whatsapp-chat-button-msn {
        margin-left: 8px;
        margin-right: 8px;
        z-index: 1;
        color: ${colorChatButton};
    }`
}