import { CSSProperties } from "react";
import './HTML.css';
interface Props {
    className?: string;
    style?: any;
    html: any[]
}
export function Html({ className, style, html }: Props) {
    return <div style={style} className={className} >
        {html.map(e => <VerElement element={e}>
            {e.children.map((a: any) => <VerLeaf leaf={a} />)}
        </VerElement>)}
    </div>
}
export const VerElement = (p: { element: any, children: any }) => <Block {...p} />
const Block = ({ element, children }: { element: any, children: any }) => {
    switch (element.type) {
        case 'heading-one': return <h1 style={getCSS(element)}>{children}</h1>
        case 'heading-two': return <h2 style={getCSS(element)}>{children}</h2>
        case 'heading-tre': return <h3 style={getCSS(element)}>{children}</h3>
        case 'heading-for': return <h4 style={getCSS(element)}>{children}</h4>
        default: return <p style={getCSS(element)}>{children}</p>
    }
}
export const VerLeaf = ({ leaf }: { leaf: any }) => {
    const { color, fontFamily, fontSize, bold, italic, underline, text } = leaf;
    const style = {
        color, fontFamily,
        fontSize: fontSize ? `${fontSize / 10}rem` : undefined,
        fontWeight: bold ? 'bold' : 'normal',
        fontStyle: italic ? 'italic' : 'normal',
        textDecoration: underline ? 'underline' : 'none',
    };
    return <span style={style} dangerouslySetInnerHTML={{ __html: text.replaceAll('¤', '"') }} />
}
const getCSS = ({ align, tabs, type, list }: any): CSSProperties => {
    const font = getFontSize(type) / 10;
    const t2 = (tabs * 2);
    const t = list === 'paragraph' ? t2 : t2 + font;
    const s = list === 'paragraph' ? {} : {
        display: 'list-item',
        listStyleType: list === 'bulleted-list' ? 'disc' : 'square'
    }
    return {
        float: 'right',
        textAlign: align,
        padding: '0 0 0 0',
        width: `calc(100% - ${t + .1}rem)`,
        margin: '.05rem .05rem .05rem .05rem',
        ...s
    }
}
const getFontSize = (type: any) => {
    if (type === 'heading-one') return 26;
    if (type === 'heading-two') return 22;
    if (type === 'heading-tre') return 18;
    if (type === 'heading-for') return 14;
    return 10;
}
