import { Html } from "../Components/Html";
import { footer } from "../Data";
import { getImagenURL } from "../Utils/Imagen";

export default function Footer() {
    const { imgPosicion, backgroundColor, img, left, center, right } = footer;
    const TextCols = ({ p, html }: any) => <Html className={`${className(p)} h-100`} html={html} />;
    const ImgCol = () => <div className="col-4 col-lg-2"><img width='100%' height='fit-content' className="img-responsive" src={getImagenURL(img.replace('/public', ''))} alt="Jango" /></div>;
    const className = (p: boolean) => p ? 'col-8 col-lg-4' : 'col-12 col-md-6 col-lg-3';
    return <footer className="c-layout-footer c-layout-footer-1" style={{ backgroundColor }}>
        <div className="c-prefooter">
            <div className="container c-last">
                <div className="row" style={{ padding: '2rem' }}>
                    {imgPosicion === 1 ? <ImgCol /> : <></>}
                    <TextCols p={[1, 2].some(p => p === imgPosicion)} html={left} />
                    {imgPosicion === 2 ? <ImgCol /> : <></>}
                    <TextCols p={false} html={center} />
                    {imgPosicion === 3 ? <ImgCol /> : <></>}
                    <TextCols col={[3, 4].some(p => p === imgPosicion)} html={right} />
                    {imgPosicion === 4 ? <ImgCol /> : <></>}
                </div>
            </div>
        </div>
    </footer>
}