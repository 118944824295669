import { Container } from 'react-bootstrap';
import { Invert } from '../Components/Invert';
import { getImagenURL } from '../Utils/Imagen';
export default function Imagen({ data }: any) {
    const { id, props: { padding, backgroundColor, img, aspectRatio } } = data;
    return <div style={{ paddingBlock: padding, background: backgroundColor }}>
        <Container>
            <Invert data={data}>
                <img src={getImagenURL(img)} style={{ aspectRatio }} width='100%' alt={`inverI ${id}`} />
            </Invert>
        </Container>
    </div>
}
