import { useState, useEffect, useCallback } from "react";
import { Modal, Button, Col } from "react-bootstrap";
import { ToRGB } from "../Utils/Formatos";
import './Zoom.css';
import { getImagenURL } from "../Utils/Imagen";
interface Props {
    imgs: { id: number, src: string }[]
    select: number
    setSelect: (select: number) => void
    open: boolean
    setOpen: (o: boolean) => void
    color: string
}
//popOutLeft popInLeft
export function Zoom({ imgs, select, open, setSelect, setOpen, color }: Props) {
    const index = imgs.findIndex(i => i.id === select);
    const prev = imgs[index > 0 ? index - 1 : imgs.length - 1].id;
    const next = imgs[index < imgs.length - 1 ? index + 1 : 0].id;
    const [animated, setAnimation] = useState(false);
    useEffect(() => { setAnimation(true); setTimeout(() => { setAnimation(false); }, 1000); }, [select, open])
    const getClass = useCallback((id: number) => { return select !== id ? 'hidden' : animated ? 'flipInY' : ''; }, [animated, select]);
    return <Modal Modal className="zoom" style={{ overflow: 'hidden' }} animation show={open} onHide={() => setOpen(false)}>
        <div className='d-flex m-0 p-0 modal-ab'
            style={{ width: window.innerWidth, height: window.innerHeight }}>
            <ButtonMove color={color} icon={iPre} click={() => setSelect(prev)} />
            <Col style={{ height: '100vh', ...gridColumn }} className='m-0 p-0'>
                <div onClick={() => setOpen(false)} />
                <Col style={{ ...gridRow }} className='m-0 p-0'>
                    <Col className='m-0 p-0 h-100' onClick={() => setOpen(false)} />
                    <div className='m-auto h-100' style={{ textAlign: 'center' }} >
                        {imgs.map(i => <Image next={() => setSelect(next)} src={getImagenURL(i.src)}
                            key={`img${i.id}`} className={getClass(i.id)} />)}
                    </div>
                    <Col className='m-0 p-0 h-100' onClick={() => setOpen(false)} />
                </Col>
                <div onClick={() => setOpen(false)} />
            </Col>
            <ButtonMove color={color} icon={iNext} click={() => setSelect(next)} />
        </div>
    </Modal >
}

function Image({ src, className, next }: { src: string, className: string, next: () => void }) {
    return <img src={src} style={{ textAlign: 'center', maxWidth: '90vw', maxHeight: '90vh' }}
        onClick={next} className={`vivify animationObject ${className}`} alt='galeria' />
}

function ButtonMove({ icon, click, color }: { icon: any, click: () => void, color: string }) {
    return <Button onClick={click}
        style={{ height: '100%', width: '120px', backgroundColor: color, color: getColor(color) }}>
        {icon}
    </Button>
}
const gridColumn = {
    display: 'grid',
    gridAutoFlow: 'dense',
    gridAutoRows: `1fr min-content 1fr`,
    gridTemplateColumns: `repeat(auto-fill,100%)`
}
const gridRow = {
    display: 'grid',
    gridAutoFlow: 'dense',
    gridAutoRows: `repeat(auto-fill,100%)`,
    gridTemplateColumns: `1fr min-content 1fr`
}

const iPre = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z" /></svg>;
const iNext = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z" /></svg>;

function getColor(color: string) {
    const { r, g, b } = ToRGB(color);
    let r1 = r >= 128 ? 0 : 255;
    let g1 = g >= 128 ? 0 : 255;
    let b1 = b >= 128 ? 0 : 255;
    const max = Math.max(r1, g1, b1);
    const min = Math.min(r1, g1, b1);
    if (max !== min) {
        if (r1 === g1) b1 = b1 === min ? Math.max(r, g, b) : Math.min(r, g, b);
        if (r1 === b1) g1 = g1 === min ? Math.max(r, g, b) : Math.min(r, g, b);
        if (b1 === g1) r1 = r1 === min ? Math.max(r, g, b) : Math.min(r, g, b);
    }
    return `rgb(${r1},${g1},${b1})`;
}