import { Navbar, Nav, Container } from 'react-bootstrap';
import { css } from '@emotion/css';
import { panels, page, menu } from '../Data';
import { filtarPanels, stringToLink, ToRGB } from '../Utils/Formatos';
import { Link } from "react-router-dom";
import { Html } from '../Components/Html';
import { getImagenURL } from '../Utils/Imagen';

export default function Menu() {
    const { height, html, orientacion, backgroundColor, color, backgroundColorHover, colorHover, img } = menu;
    return <>
        <Navbar fixed='top' expand="lg" className={getCSS(backgroundColor, color, backgroundColorHover, colorHover)}>
            <Container>
                <div className='d-flex'>
                    <img height={height + 'px'} width='content-fit'
                        className="img-responsive" src={getImagenURL(img.replace('/public', ''))} alt="Jango" />
                    <Html html={html} />
                </div>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className={`m${orientacion ? 'l' : 'r'}-auto`}>
                        {page.length === 1 ?
                            panels.filter(({ title, estado }) => title !== '' && estado === 1)
                                .map(({ title, id }) => <Nav.Link key={`panel${id}`} onClick={() => ir(`panel${id}`)} ><b>{title}</b></Nav.Link>) :
                            page.filter(({ visible }) => visible).map(p => <NavPage key={`page${p.id}`} {...p} />)
                        }
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
        <div style={{ height: `calc(${height}px + 1rem)` }} />
    </>
}

function NavPage({ title, id, ocultar }: { title: string, id: number, ocultar: number[] }) {
    const isPanel = panels.some(({ pagina, estado }) => pagina === id && estado === 1);
    if (!isPanel) return <></>;
    const link = stringToLink(title);
    const PanelTitles = filtarPanels({ id, ocultar }, panels);
    if (PanelTitles.length)
        return <div className="dropdown">
            <Link className="nav-link dropdown-toggle" to={`/${link}`} >
                <b>{title}</b>
            </Link>
            <div className="dropdown-menu">
                {PanelTitles.map(p => <DropIten key={`panel${p.id}`} panel={p} />)}
            </div>
        </div>
    return <Nav.Link as={Link} to={`/${link}`}><b>{title}</b></Nav.Link>
}

function DropIten({ panel: { title, id } }: { panel: any }) {
    return <a onClick={() => ir(`panel${id}`)} href=' ' className='dropdown-item'>
        <b>{title}</b>
    </a>
}
export function getCSS(backgroundColor: string, color: string, backgroundColorHover: string, colorHover: string) {
    const a = `&> a {
        color: ${color} !important;
        &:hover {
            background-color: ${backgroundColorHover} !important;
            color: ${colorHover} !important;
        }
    }`;
    const toggler = (color: string) => `&> .navbar-toggler-icon{
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='${colorFormat(color)}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
    }`;
    return css`
        background-color: ${backgroundColor};
        max-width: 100vw;
        & > * {height: 100%;}
        & > .container{
            ${a}
            &> button {
                color: ${color} !important;
                border-color: ${color} !important;
                ${toggler(color)}
                &:hover {
                    background-color: ${backgroundColorHover} !important;
                    color: ${colorHover} !important;
                    border-color: ${colorHover} !important;
                    ${toggler(color)}
                }
            }
            &> .navbar-collapse> .navbar-nav> .dropdown{
                ${a}
                &> .dropdown-menu{
                    margin-top: -5px;
                    background-color: ${backgroundColor} !important;
                    border: none;
                    ${a}
                }
                &:hover> .dropdown-menu{ display: block; }
            }
        }
        &> .container> .navbar-collapse> .navbar-nav{${a}}
    `
}
const colorFormat = (color: string) => {
    const { r, g, b, a } = ToRGB(color);
    return `rgba(${r},${g},${b},${a})`;
}
function ir(id: string) {
    const s = document.getElementById(id);
    console.log(id,s)
    if (s !== null) {
        const c = s.getBoundingClientRect();
        window.scrollTo({ top: c.top, behavior: 'smooth' });
    }
}