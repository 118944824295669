import { Row, Container, Col } from 'react-bootstrap';
import { Html } from '../Components/Html';
import { getWidth } from '../Utils';
import './Mapa.css';
import { removeAlfa } from '../Utils/Formatos';
export default function Mapa({ data: { props: { xs, sm, md, lg, xl, xxl, color, mapa }, html } }: { data: any }) {
    return <div className="w-100 map-conten" style={{ backgroundColor: removeAlfa(color) }}>
        <div className="map-block" style={{ zIndex: 100 }}>
            <Container className="h-100">
                <Row className={`${getWidth(xs, sm, md, lg, xl, xxl)} map-row`}>
                    <Col style={{ background: color }}>
                        <Html style={{ height: '100%', width: '100%' }} html={html} />
                    </Col>
                </Row>
            </Container>
        </div>
        <div className="map-block">
            <iframe style={{ border: 0 }} height="100%" width="100%" src={mapa} title='mapa' />
        </div>
    </div>
}