/* eslint-disable no-new-func */
const page: any[] = Function('return page.paginas')();
const menu: any = Function('return menu')();
const chats: any = Function('return chats')();
const empresa: any = Function('return empresa')();
const footer: any = Function('return footer')();
const panels: Panel[] = Function('return panels')();
const screen = Function('return screen')() as Screen;
export { page, menu, chats, empresa, footer, panels, screen };


export interface Panel {
    id: number
    indice: number
    pagina: number
    title: string
    type: string
    data: any
    text: string
    estado: number
    items: Items[]
}
export interface Items {
    id: number
    panel_id: number
    indice: number
    type: string
    data: any
    text: string
}