import { Html } from '../../Components/Html';
import { getImagenURL } from '../../Utils/Imagen';
import './style.css';
import { SVGR, SVGL } from './SVG';
export default function Paralax1({ data: { props: { color, img, ori, variant }, html } }: { data: any }) {
    return <div style={{ backgroundColor: color }} className="box">
        <div className="paralax">
            <div style={{ aspectRatio: '16/9', float: ori ? 'left' : 'right', backgroundImage: `url(${getImagenURL(img)})` }} className="c-bg-parallax c-feature-bg c-content" />
            <div className="c-content-area c-content-ori"></div>
            <div style={{ background: `${color}`, float: ori ? 'right' : 'left' }} className="c-feature-content c-ori">
                <SVGL variant={variant} fill={color} ori={ori} />
                <div className="c-content-v-center w-100 d-flex justify-content-start">
                    <div className="c-wrapper w-100 h-100 d-flex flex-row align-items-center">
                        <Html className={`c-body w-100`} html={html} />
                    </div>
                </div>
                <SVGR variant={variant} fill={color} ori={ori} />
            </div>
        </div>
    </div>

}