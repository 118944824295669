import { css } from '@emotion/css';
import { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react';
import { Row } from 'react-bootstrap';
import { Html } from '../Components/Html';
import { getImagenURL } from '../Utils/Imagen';
import './carrusel.css';
export default function Carrusel({ data: { itens, props } }: any) {
    const [seleted, seled] = useState(1)
    const { animated } = props;
    const prev = seleted === 1 ? itens.length - 1 : seleted - 2;
    const sele = seleted - 1;
    const next = seleted === itens.length ? 0 : seleted;
    const [trans, setTrans] = useState('');
    const setTransClick = useCallback((t: string, p: number) => {
        setTrans(t);
        setTimeout(() => { setTrans(''); seled(p + 1) }, 250)
    }, []);
    const nex = useCallback(() => { setTransClick(' next', next) }, [next, setTransClick]);
    useEffect(() => {
        const interval = setInterval(() => nex(), 10000);
        return () => clearInterval(interval);
    }, [nex])
    return <Row className='m-0 p-0'>
        <div style={{ aspectRatio: '16/7', width: '100%' }} className={"carousel slide " + getCSS(animated)}>
            <ol className="carousel-indicators">
                {itens.map((i: any) => <li key={'itens' + i.id} className={seleted === i.indice ? 'active' : ''}></li>)}
            </ol>
            {useMemo(() => <div key={`carousel-inner${sele}`} className={"carousel-inner" + trans}>
                <Itens position="prev" key={'iten' + (prev + 10000)} iten={itens[prev]} />
                <Itens position="" key={'iten' + (sele + 20000)} iten={itens[sele]} />
                <Itens position="next" key={'iten' + (next + 30000)} iten={itens[next]} />
            </div>, [prev, trans, sele, next, itens])}
            <div style={{ zIndex: 5 }} className="carousel-control-prev" role="button"
                onClick={() => setTransClick(' prev', prev)}>
                <svg viewBox="0 0 8 8" width="3rem" height="3rem"
                    xmlns="https://www.w3.org/2000/svg">
                    <path fill="#fff" d="M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z" />
                </svg>
                <span className="sr-only">Previous</span>
            </div>
            <div style={{ zIndex: 5 }} className="carousel-control-next" role="button"
                onClick={() => setTransClick(' next', next)}>
                <svg width="3rem" height="3rem" viewBox="0 0 8 8"
                    xmlns="https://www.w3.org/2000/svg" >
                    <path fill="#fff" d="M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z" />
                </svg>
                <span className="sr-only">Next</span>
            </div>
        </div>
    </Row >
}
function Itens({
    position: p,
    iten: { html, indice, props: { position, disable, img, color, back, borde, bordeColor, redondius, id } } }: any) {
    return useMemo(() => <div id={`carb${id}`} className={'iten ' + p}>
        <img style={imagen} alt={`carrusel ${id}`} src={getImagenURL(img)} />
        <div className='p-0 m-0' style={{ width: "100vw", aspectRatio: "16 / 7", position: 'absolute', inset: '0 0 0 0' }}>
            <Html html={html} style={posicion[position]} key={'html' + indice}
                className={`${content(back, color, bordeColor, borde, redondius, disable)} carousel-caption`} />
        </div>
    </div >, [id, p, img, html, position, indice, back, color, bordeColor, borde, redondius, disable])
}

export const imagen: CSSProperties = { aspectRatio: "16 / 7", display: 'block', width: '100%', height: '100%' };
const all: CSSProperties = { maxWidth: 'calc(100% - 80px)', width: 'fit-content' }
//top right bottom left
export const posicion = [
    //posicion 0 arriba izquierda
    { ...all, inset: '20px calc(100% - 20px) auto auto', transform: 'translateX(100%)' },
    //posicion 1 arriba centro
    { ...all, inset: '20px 50% auto auto', transform: 'translateX(50%)' },
    //posicion 2 arriba derecha
    { ...all, inset: '20px auto auto calc(100% - 20px)', transform: 'translateX(-100%)' },
    //posicion 3 medio izquierda
    { ...all, inset: '50% 30% auto 20px', transform: 'translateY(-50%)' },
    //posicion 4 medio centro
    { ...all, inset: '50% 50% auto auto', transform: 'translate(50%, -50%)' },
    //posicion 5 medio derecha
    { ...all, inset: '50% auto auto calc(100% - 20px)', transform: 'translate(-100%, -50%)' },
    //posicion 6 abajo izquierda
    { ...all, inset: 'auto calc(100% - 20px) 30px auto', transform: 'translateX(100%)' },
    //posicion 7 abajo centro
    { ...all, inset: 'auto 50% 30px auto', transform: 'translateX(50%)' },
    //posicion abajo derecha
    { ...all, inset: 'auto auto 30px calc(100% - 20px)', transform: 'translateX(-100%)' },
]


export function content(
    color: string, font: string, borderColor: string, border: number, radius: number, disable: boolean) {
    return css`
        padding: 1rem 1rem 1rem 1rem;
        color: ${font};
        min-width: 40vw;
        ${disable ? '' : `
            border: ${border}px solid ${borderColor};
            background: ${color};
            border-radius: ${radius}px;
        `}`;
}

export function getCSS(a: animatedList) {
    return css`
    width: 100%;
    overflow: hidden;
    &>.carousel-inner{
        ${aux[a].inert}
    }
    &> .carousel-inner.prev> *,
    &> .carousel-inner.next> *,{
        transition: all 0.25s;
    }
    &> .carousel-inner> .iten{
        ${aux[a].stop}
    }
    ${aux[a].prev}
    ${aux[a].next}`;
}
export type animatedList = 'translateX' | 'translateY' | 'scaled' | 'scaledY' | 'scaledX' | 'rotate' | 'translateXI' | 'translateYI' | 'scaledI' | 'scaledYI' | 'scaledXI' | 'rotateI';
const iner = '&> .carousel-inner';
const prev = `${iner}.prev> .iten`;
const next = `${iner}.next> .iten`;
const aux = {
    translateX: {
        type: 'inner',
        inert: 'width: 300%;margin-left: -100%;',
        prev: `${prev}{transform: translateX(100%);}`,
        next: `${next}{transform: translateX(-100%);}`,
        stop: `position: relative;
            display: inline-block;
            width: 33.333333%;
            height: 100%;
            overflow: hidden;`
    },
    translateY: {
        type: 'inner',
        inert: 'height: 300%;margin-top: -43.7%;',
        prev: `${prev}{transform: translateY(100%);}`,
        next: `${next}{transform: translateY(-100%);}`,
        stop: `
            position: relative;
            display: inline-block;
            width: 100%;
            height: 33.333333%;
            overflow: hidden;`
    },
    scaled: {
        type: 'iten',
        inert: 'height: 100%;width: 100%;',
        prev: `
            ${iner}> .iten.prev{z-index:1;}
            ${prev}{transform: scale(0, 0);}
            ${iner}> .iten.prev{transform: scale(1, 1);}`,
        next: `
            ${iner}> .iten.next{transform: scale(0, 0);z-index:3;}
            ${next}.next{transform: scale(1, 1);}`,
        stop: `
            z-index:2;
            position: absolute;
            top:0;left:0;right:0;bottom:0;
            display: inline-block;
            width: 100%;
            height: 100%;            
            overflow: hidden;`
    },
    scaledY: {
        type: 'iten',
        inert: 'height: 100%;width: 100%;',
        prev: `
            ${iner}> .iten.prev{z-index:1;}
            ${prev}{transform: scaleY(0);}
            ${iner}> .iten.prev{transform: scaleY(1);}`,
        next: `
            ${iner}> .iten.next{transform: scaleY(0);z-index:3;}
            ${next}.next{transform: scaleY(1);}`,
        stop: `
            z-index:2;
            position: absolute;
            top:0;left:0;right:0;bottom:0;
            display: inline-block;
            width: 100%;
            height: 100%;            
            overflow: hidden;`
    },
    scaledX: {
        type: 'iten',
        inert: 'height: 100%;width: 100%;',
        prev: `
            ${iner}> .iten.prev{z-index:1;}
            ${prev}{transform: scaleX(0);}
            ${iner}> .iten.prev{transform: scaleX(1);}`,
        next: `
            ${iner}> .iten.next{transform: scaleX(0);z-index:3;}
            ${next}.next{transform: scaleX(1);}`,
        stop: `
            z-index:2;
            position: absolute;
            top:0;left:0;right:0;bottom:0;
            display: inline-block;
            width: 100%;
            height: 100%;            
            overflow: hidden;`
    },
    rotate: {
        type: 'iten',
        inert: 'height: 100%;width: 100%;',
        prev: `
            ${iner}> .iten.prev{z-index:1;}
            ${prev}{transform: scale(0, 0) rotate(-240deg);}
            ${iner}> .iten.prev{transform: scale(1, 1) rotate(0deg);}`,
        next: `
            ${iner}> .iten.next{transform: scale(0, 0) rotate(-240deg);z-index:3;}
            ${next}.next{transform: scale(1, 1) rotate(0deg);}`,
        stop: `
            z-index:2;
            position: absolute;
            top:0;left:0;right:0;bottom:0;
            display: inline-block;
            width: 100%;
            height: 100%;            
            overflow: hidden;`
    },
    translateXI: {
        type: 'inner',
        inert: 'width: 300%;margin-left: -100%;',
        prev: `${prev}{transform: translateX(-100%);}`,
        next: `${next}{transform: translateX(100%);}`,
        stop: `position: relative;
            display: inline-block;
            width: 33.333333%;
            height: 100%;
            overflow: hidden;`
    },
    translateYI: {
        type: 'inner',
        inert: 'height: 300%;margin-top: -43.7%;',
        prev: `${prev}{transform: translateY(-100%);}`,
        next: `${next}{transform: translateY(100%);}`,
        stop: `position: relative;
            display: inline-block;
            width: 100%;
            height: 33.333333%;
            overflow: hidden;`
    },
    scaledI: {
        type: 'iten',
        inert: 'height: 100%;width: 100%;',
        prev: `
            ${iner}> .iten.prev{transform: scale(0, 0);z-index:3;}
            ${prev}.prev{transform: scale(1, 1);}`,
        next: `
            ${iner}> .iten.next{z-index:1;}
            ${next}{transform: scale(0, 0);}
            ${iner}> .iten.next{transform: scale(1, 1);}`,
        stop: `
            z-index:2;
            position: absolute;
            top:0;left:0;right:0;bottom:0;
            display: inline-block;
            width: 100%;
            height: 100%;            
            overflow: hidden;`
    },
    rotateI: {
        type: 'iten',
        inert: 'height: 100%;width: 100%;',
        prev: `
            ${iner}> .iten.prev{transform: scale(0, 0) rotate(-240deg);z-index:3;}
            ${prev}.prev{transform: scale(1, 1) rotate(0deg);}`,
        next: `
            ${iner}> .iten.next{z-index:1;}
            ${next}{transform: scale(0, 0) rotate(-240deg);}
            ${iner}> .iten.next{transform: scale(1, 1);}`,
        stop: `
            z-index:2;
            position: absolute;
            top:0;left:0;right:0;bottom:0;
            display: inline-block;
            width: 100%;
            height: 100%;            
            overflow: hidden;`
    },
    scaledYI: {
        type: 'iten',
        inert: 'height: 100%;width: 100%;',
        prev: `
            ${iner}> .iten.prev{transform: scaleY(0);z-index:3;}
            ${prev}.prev{transform: scaleY(1);}`,
        next: `
            ${iner}> .iten.next{z-index:1;}
            ${next}{transform: scaleY(0);}
            ${iner}> .iten.next{transform: scaleY(1);}`,
        stop: `
            z-index:2;
            position: absolute;
            top:0;left:0;right:0;bottom:0;
            display: inline-block;
            width: 100%;
            height: 100%;            
            overflow: hidden;`
    },
    scaledXI: {
        type: 'iten',
        inert: 'height: 100%;width: 100%;',
        prev: `
            ${iner}> .iten.prev{transform: scaleX(0);z-index:3;}
            ${prev}.prev{transform: scaleX(1);}`,
        next: `
            ${iner}> .iten.next{z-index:1;}
            ${next}{transform: scaleX(0);}
            ${iner}> .iten.next{transform: scaleX(1);}`,
        stop: `
            z-index:2;
            position: absolute;
            top:0;left:0;right:0;bottom:0;
            display: inline-block;
            width: 100%;
            height: 100%;            
            overflow: hidden;`
    },
} 