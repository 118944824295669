import { Row } from 'react-bootstrap';
import { getOrientacion } from '../Utils';
import { Html } from './Html';

export function Invert({ data: { props: { lg, md, ori, sm, textColor, childColor = '#f0f0f000', texto, xl, xs, xxl }, html }, children }: { data: any, children: any }) {
    const invert = (orie: boolean) => {
        if (!texto || orie)
            return <div className={`${getOrientacion(ori, xs, sm, md, lg, xl, xxl)}`}
                style={{ background: childColor, width: texto ? undefined : '100%', position: 'relative', float: 'left', }}>
                {children}
            </div >
        return <div className={`${getOrientacion(!ori, xs, sm, md, lg, xl, xxl)}`}
            style={{ background: textColor, position: 'relative', float: 'left', padding: '30px' }}>
            <Html style={{ margin: 0, position: 'relative', height: '100%', float: 'left' }} html={html} />
        </div>
    }
    return <Row className='d-flex' style={{ minHeight: '150px' }}>
        {invert(ori)}
        {texto ? invert(!ori) : <></>}
    </Row>
}