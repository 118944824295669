import { css } from '@emotion/css';
import { useState, useMemo } from 'react'
import { Col, Row } from 'react-bootstrap';
import { Preview } from '.';
import { Html } from '../Components/Html';
import { Icon2 } from '../Components/Icons';
import { panels } from '../Data';
import "./Pestanas.css";
export default function Pestanas({ data: { props, itens, id } }: { data: { props: any, itens: any[], id: any } }) {
    const [select, setSelect] = useState(1);
    const { color, tap, tapTitle, tapPosition, font, variant } = props;
    const selectIten = itens.find((i: any) => i.indice === select) as any;
    const minHeight = (tapPosition === 'row' || tapPosition === 'row-reverse') ? itens.length * 60 : undefined;
    return <div className={`pestanas ${getCSS(tap, tapTitle, font)} m-0 d-flex flex-${tapPosition}`}
        style={{ background: color, padding: '10px 0 10px 0' }}>
        <Row as='ul' className={`m-0 p-0 ${variant}`} style={{ minHeight }}>
            {itens.map((iten: any, i: number) => <Tap key={`itens${id}${i}`}
                selected={select} config={props} select={setSelect} iten={iten} />)}
        </Row>
        <Itens key={`iten${id}${selectIten.id}`} iten={selectIten} />
    </div>
}
function Itens({ iten }: any) {
    const panel = panels.find(p => p.id === iten.props.panel)
    return useMemo(() => (panel !== null) ? <Preview panel={panel} key={`panel${iten.props.panel}`} /> : <></>, [panel, iten.props.panel])
}
function Tap({ config, select, iten, selected }: any) {
    const { indice, html, props: { icon, fill, aux } } = iten;
    const { orientation, back, border, font } = config;
    const zIndex = selected === indice ? 20 : indice > selected ? 20 - (indice - selected) : 20 - (selected - indice);
    return <Col style={{ zIndex }} onClick={() => select(indice)} as='li'
        className={`p-1 d-flex flex-${orientation} ${selected === indice ? 'active' : ''}`}>
        <div className={"icon-content text-center"}>
            <Icon2 icon={icon} font={font} fill={fill} back={back}
                border={border} aux={aux} />
        </div>
        <Html style={{ minWidth: 'calc(100% - 3rem)' }} html={html}
            className='d-flex align-items-center h-100 p-1' />
    </Col>
}
function getCSS(back: string, color: string, font: number) {
    return css`
        &> ul> li{
            background: ${back};
            color: ${color};
            border: 1px solid  ${color};
        }
        &.flex-row,
        &.flex-row-reverse {
            &> ul{
                &> li{max-height: ${(font * 1.65) + 1}rem;}
                &>.flex-column-reverse,
                &>.flex-column{max-height: ${(font + 1.5) + 2}rem;}
            }
        }
    `;
}

