import { Row, Container } from 'react-bootstrap';
import { Html } from '../Components/Html';
import { Icon2 } from '../Components/Icons';
export default function Servicios({ data: { id, itens, props } }: any) {
    const { color, gap } = props;
    return <Row className='m-0' style={{ background: color, padding: '10px 0 10px 0' }}>
        <Container style={{ display: 'grid', gap: `${gap}px`, gridAutoFlow: 'dense', gridTemplateColumns: `repeat(auto-fill, minmax(320px, 1fr))` }}>
            {itens.map((i: any) => <Itens key={`iten${id}${i.id}`} iten={i} config={props} />)}
        </Container>
    </Row>
}
function Itens({
    config: { card, border1, border, back: iback, orientation, font },
    iten: { html, props: { icon, color, back, aux, fill } } }: any) {
    const style = {
        border: (border1) ? `5px solid ${fill}` : undefined,
        background: card !== 'none' ? back : undefined,
        borderRadius: card === 'rounded' ? `${font / 3}rem` : undefined,
        padding: '10px 10px 10px 10px', width: '100%', height: '100%'
    };
    const className = orientation.indexOf('column') > -1 ? "d-flex justify-content-center" : "d-flex align-items-center h-100";

    return <div style={{ minHeight: `${font}rem` }} className='m-0 p-0'>
        <div style={style} className={`d-flex flex-${orientation}`}>
            <div className={className}>
                <Icon2 icon={icon} font={font} fill={fill} back={iback} border={border} aux={aux} />
            </div>
            <Html style={{ color }} html={html} className="d-flex align-items-center h-100 p-1" />
        </div>
    </div>
}