import { Row, Container } from 'react-bootstrap';
import { Itens } from './Iten'
import { RowItens } from './RowIten'
export default function Servicio2({ data: { id, itens, props } }: any) {
    const Rows: JSX.Element[] = [];
    for (let i = 0; i < itens.length; i += 2) {
        Rows.push(<RowItens font={props.font} key={`RowItens${i}}`}
            left={<Itens ori='left' i={i} key={`iten${id}${itens[i].id}`} iten={itens[i]} config={props} />}
            right={<Itens ori='right' i={i + 1} key={`iten${id}${itens[i + 1].id}`} iten={itens[i + 1]} config={props} />} />)
    }
    return <Row className='m-0' style={{ background: props.color, padding: '10px 0 10px 0' }}>
        <Container>{Rows}</Container>
    </Row>
}