import { Row, Container, Card, Col, Image } from 'react-bootstrap';
import "./Testimonios.css";
import { lazy, Suspense } from 'react';
import { Html } from '../Components/Html';
import { getImagenURL } from '../Utils/Imagen';
import { getCSSCarrusel } from './Cargos/styles';
import Cargando from './Cargando';
const SliderLazy = lazy(() => import('../Utils/SliderLazy'));
export default function Testimonio({ data: { itens, props } }: any) {
    let settings = {
        slidesToShow: 1,
        slidesToScroll: 1
    }
    const style = { with: '100%', margin: '0 0 0 0', padding: '0 0 0 0' };
    const { color, img, inter } = props
    const i = itens.map((i: any) => <Itens key={'itens' + i.id} iten={i} props={props} />)
    return <div className="m-0 p-0 w-100" style={{ background: `url(${getImagenURL(img)})`, backgroundSize: 'cover' }}>
        <Row style={{ background: color, ...style, padding: '0 0 2rem 0' }}>
            <Container className={`${getCSSCarrusel(inter)} testimonial`}>
                <Suspense fallback={<Cargando />}>
                    <SliderLazy settings={settings}>
                        {i}{i}
                    </SliderLazy>
                </Suspense>
            </Container>
        </Row>
    </div>
}
function Itens({ iten: { id, props: { marco, borde, width, img, color, back, top, left, right, bottom } } }: any) {
    const style = { background: back, color };
    const w = width / 20;
    return <Col xs={12} className='h-100 pl-1 pr-1 d-flex flex-column justify-content-center'>
        <Card style={style} >
            <Card.Body>
                <div className="m-0 p-0 d-flex flex-column">
                    <IsHTML p={1}  html={top} />
                    <Row className='d-flex flex-row justify-content-center'>
                        <IsHTML p={2} width={getWidth(!!left, !!right, w)} html={left} />
                        <Row className='pl-1 pr-1 d-flex flex-row justify-content-center' >
                            <div className='m-0 p-0 w-100'>
                                <Image alt={`testigo ${id}`} src={getImagenURL(img)}
                                    style={{ maxWidth: w + 'rem', background: color, border: '0px', aspectRatio: 1 }}
                                    className={`${marco} ${borde ? 'img-thumbnail' : ''}`} />
                            </div>
                        </Row>
                        <IsHTML p={2} width={getWidth(!!left, !!right, w)} html={right} />
                    </Row>
                    <IsHTML p={1}  html={bottom} />
                </div>
            </Card.Body>
        </Card>
    </Col >
}
const getWidth = (l: boolean, r: boolean, w: number) => {
    if (l && r) return `calc(50% - ${w / 2}rem)`;
    return `calc(100% - ${w}rem)`;
}
const IsHTML = ({ html, width="100%", p = 0 }: any) => {
    if (!html) return <></>
    const w = { width, maxWidth: width };
    return <Row style={w} className={'m-0 p-' + p}>
        <Html className='w-100' html={html} />
    </Row>
}