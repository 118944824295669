import { css } from "@emotion/css";
import { MobileUserAgent, ToRGB } from "@victorequena22/utiles";

export function getCSSCarrusel(color: string) {
    if (MobileUserAgent()) {
        return css`
        padding-inline: 2.01rem;
        .slick-prev, 
        .slick-next,
        .slick-dots{
            display: none !important;
        }`;
    }
    const [r, g, b] = getColor(color);
    let r1 = r >= 128 ? 0 : 255;
    let g1 = g >= 128 ? 0 : 255;
    let b1 = b >= 128 ? 0 : 255;
    const max = Math.max(r1, g1, b1);
    const min = Math.min(r1, g1, b1);
    if (max !== min) {
        if (r1 === g1) b1 = b1 === min ? Math.max(r, g, b) : Math.min(r, g, b);
        if (r1 === b1) g1 = g1 === min ? Math.max(r, g, b) : Math.min(r, g, b);
        if (b1 === g1) r1 = r1 === min ? Math.max(r, g, b) : Math.min(r, g, b);
    }
    let c = `
    .slick-prev:before, 
    .slick-next:before,
    .slick-dots li button:before{
        color: rgb(${r1},${g1},${b1}) !important;
    }`;
    return css`
    ${c}
    ${getMovil()}
    `;
}

const getMovil = () => {
    if (MobileUserAgent()) {
        return `
    .slick-prev, 
    .slick-next,
    .slick-dots li button{
        display: none;
    }`;
    }
    //     return `
    // padding-inline: 2.01rem;
    // .slick-prev:before, 
    // .slick-next:before{
    //     font-size: 1.5rem
    // }
    // .slick-dots li button:before{
    //     font-size: 1rem
    // }`;
    return ``;
}


const getColor = (color: string) => {
    if (color.indexOf('#') > -1) {
        const { r, g, b } = ToRGB(color);
        return [r, g, b];
    }
    if (color.indexOf('linear-gradient') > -1) {
        const c = color.replace('linear-gradient', '').split(' ');
        const [r1, g1, b1] = getRGB(c[1]);
        const [r2, g2, b2] = getRGB(c[3])
        return [Math.floor((r1 + r2) / 2), Math.floor((g1 + g2) / 2), Math.floor((b1 + b2) / 2)];

    }
    return getRGB(color);
}

const getRGB = (color: string) => {
    return color
        .replace('RGB(', '')
        .replace('RGBA(', '')
        .replace('rgb(', '')
        .replace('rgba(', '')
        .replace(')', '')
        .split(',')
        .map(c => parseInt(c))
}