interface Props {
    variant: string;
    fill: string;
    ori: boolean;
}
export function SVGR({ variant, fill: f, ori }: Props) {
    if (ori) return <></>
    const path = () => {
        switch (variant) {
            case 'arrow-i': return `M 0,0 0,600 330,600 30,300 300,0 Z`;
            case 'arrow': return `M0,0v600h30l300-300L30,0H0z`;
            case 'semi-circle': return `M0,0v600h330c0,0-300,0-300-300S330,0,330,0H0z`;
            // case 'D': return `m 0,0 v 600 l 30,0 c 0,0 300,0 300,-300 C 330,0 30,0 30,0 Z`;
            case 'D': return `M0,0v600l0,0c0,0,200,10,200-300C200-1.5,0,0,0,0z`;
            case 'diagonal': return `M30,0H0v600h330`;
            case 'diagonal-i': return `M330,0H0v600h30`;
            case 'onda': return `M0,0v600h100c0,0-68-3-70-100c-3-147,195-103,195-300C225,24.2,100,0,100,0H0z`;
            case 'onda-i': return `M100,600c0,0,125-24.2,125-200c0-197-198-153-195-300C32,3,100,0,100,0H0v600H100z`;
            default: return "";
        }
    }
    let fill = getFill(f);
    return <svg className="svg-border" style={{ position: 'absolute', transform: 'translateY(-100%)', left: 'calc(100% - 1px)', float: 'left' }}
        height='100%' viewBox="0 0 330 600"
        version="1.1" xmlns="http://www.w3.org/2000/svg">
        <path style={{ fill }} d={path()} />
    </svg>
}
export function SVGL({ variant, fill: f, ori }: Props) {
    if (!ori) return <></>
    const path = () => {
        switch (variant) {
            case 'arrow-i': return `M330,0v600H0l300-300L0,0H330z`;
            case 'arrow': return `M330,0v600h-30L0,300L300,0H330z`;
            case 'semi-circle': return `M330,0v600H0c0,0,300,0,300-300S0,0,0,0H330z`;
            // case 'D': return `m 330,0 v 600 h -30 c 0,0 -300,0 -300,-300 0,-300 300,-300 300,-300 z`;
            case 'D': return `M330,0v600l0,0c0,0-230,10-230-300C100,0,330,0,330,0z`;
            case 'diagonal': return `M0,600h330V0l-30,0`;
            case 'diagonal-i': return `M300,600h30V0L0,0`;
            case 'onda': return `M230,0c0,0-125,24.2-125,200c0,197,198,153,195,300c-2,97-70,100-70,100h100V0L230,0z`;
            case 'onda-i': return `M330,600V0H230c0,0,68,3,70,100c3,147-195,103-195,300c0,175.8,125,200,125,200H330z`;
            default: return "";
        }
    }
    let fill = getFill(f);
    return <svg className="svg-border" style={{ position: 'absolute', marginBottom: '-750px', right: 'calc(100% - 1px)', float: 'right' }}
        height='100%' viewBox="0 0 330 600"
        version="1.1" xmlns="http://www.w3.org/2000/svg">
        <path style={{ fill }} d={path()} />
    </svg>
}

function getFill(f: string) {
    if (f.includes('linear-gradient')) {
        let a = f.replace('linear-gradient(', '').replace('%)', '%');
        let b = a.split(' ');
        return b[1];
    }
    if (f.includes('radial-gradient')) {
        let a = f.replace('radial-gradient(', '').replace('%)', '%');
        let b = a.split(' ');
        return b[3];
    }
    return f;
}