import { Html } from '../../Components/Html';
import { getImagenURL } from '../../Utils/Imagen';

export function Itens({ config: { animated, width: w2, gap }, click,
    iten: { id, html, props: { img, col, row, margin, color, back } } }: any) {
    const width = ((col * w2) + ((col - 1) * gap));
    const height = ((row * w2) + ((row - 1) * gap));
    return <div style={{ aspectRatio: width / height, gridRow: `span ${row}`, gridColumn: `span ${col}`, maxWidth: 'calc(100vw - 2rem)' }}>
        <div onClick={() => click()} style={{ width: '100%', height: '100%' }}
            className={`gal-iten ${animated}`}>
            <img style={{ display: 'block' }} className='img img-fluid'
                alt={`galeria ${id}`} height='100%' width='100%' src={getImagenURL(img)} />
            <Html style={{
                background: `${back}`, color: `${color}`,
                margin: `${margin}px ${margin}px ${margin}px ${margin}px`
            }} className="caption" html={html} />
        </div >
    </div>
}
